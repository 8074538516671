(function () {
  'use strict';

  angular
    .module('forgotPassword')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('forgotPassword', {
        url: '/forgot-password',
        views: {
		alerts: {
			   templateUrl: 'alerts/alerts.tpl.html',
			   controller: 'AlertsCtrl',
			   controllerAs: 'alerts'
			},
         main: {
           templateUrl: 'forgot-password/forgot-password.tpl.html',
           controller: 'ForgotPasswordCtrl',
           controllerAs: 'ForgotPassword'
         },
		 footer: {
           templateUrl: 'footer/footer.tpl.html',
           controller: 'FooterCtrl',
           controllerAs: 'footer'
         }
       }
      });
  }
}());
